var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"TJ01fM7ocNyM3B-zOk8NV"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const appEnvironment = process.env.NEXT_PUBLIC_APP_ENV;
const isEnabled = ['test', 'prod'].includes(appEnvironment);

Sentry.init({
  debug: false,
  enabled: isEnabled,
  environment: appEnvironment,
  dsn: 'https://fc4d4a87f9f649d0b7b79ccd7753af9c@o1318498.ingest.sentry.io/6573062',
  tracesSampleRate: 0,
});
